<template>
    <v-app id="vapp">
        <v-main>
            <Transition name="fade-transition" mode="out-in">
                <router-view />
            </Transition>
        </v-main>
    </v-app>
</template>

<script>

    export default {
        name: 'App',

        data: () => ({
            //
        }),
    };
</script>

<style>


    #vapp {
        background-color: var(--v-background-base);
        overflow-y: auto;
        max-height: 100vh;
        --wellbeing-gradient: linear-gradient(to right, #4966cf, #5d6cd4, #6e72d8, #7e78dd, #8d7ee1, #9c7ee2, #aa7ee3, #b87ee3, #cb77dd, #dc70d5, #ec67ca, #fb5fbe);
    }

    @supports (max-height: 100dvh) {
        #vapp {
            max-height: 100dvh !important;
        }
    }

    .v-input input:autofill {
        font-family: "Roboto", sans-serif !important;
    }

    .v-input input:-webkit-autofill {
        font-family: "Roboto", sans-serif !important;
    }

    .v-skeleton-loader__button {
        width: 100% !important;
    }

    .swal2-popup {
        /* font-size: 1.15rem !important;*/
        font-family: "Roboto", sans-serif !important;
    }


    @media only screen and (min-width:769px) {
        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 5px;
            background: rgba(205, 205, 205, 1);
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: rgba(155, 155, 155, 1);
            -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
        }

            ::-webkit-scrollbar-thumb:hover {
                background: rgba(145, 145, 145, 1);
            }
    }

    .v-btn--active.no-active::before {
        opacity: 0 !important;
    }

    .v-btn--active.no-active:not(:hover)::before {
        opacity: 0 !important;
    }

    .v-card__text {
        color: rgb(0 0 0 / 80%) !important;
    }

    .v-skeleton-loader__heading {
        margin-bottom: 10px !important;
    }

    .v-skeleton-loader__list-item-avatar-two-line {
        height: 35px !important;
    }

    .v-skeleton-loader__avatar {
        height: 25px !important;
        width: 25px !important;
    }

    .coll-panel {
        margin-bottom: 8px;
    }

    .coll-content {
        margin-top: 20px;
    }

    .coll-header {
        font-size: 16px;
        background-color: var(--v-secondary-base) !important;
        color: white !important;
    }
</style>